


































































import { promiseHelper } from '@/helper/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'

@Observer
@Component({
  components: {
    VueHcaptcha,
  },
})
export default class RegistrationDialog extends Vue {
  @Ref('vueHcaptcha') vueHcaptcha: any
  @Prop() vm!: IdoApplyViewModel
  resolve?: (arg: any) => void
  loading = false
  hcaptchaOnOpen() {
    this.loading = false
    this.vm.resetHCaptchaToken()
  }

  async requestChangllenge() {
    this.loading = true
    this.vueHcaptcha.hcaptcha.execute()
  }
  async hcaptchaOnVerify(token) {
    this.vm.createApply(token)
  }
}
